.App {
  background-color: black;
  color: white;
}

.App-body {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.trash-title {
  font-family: "WhoDares";
}
